export const STATUS_CD = [
  { label: "New", value: "NEW" },
  { label: "In Progress", value: "WIP" },
  { label: "Submitted", value: "SUBMITTED" },
  { label: "Pending", value: "PENDING" },
  { label: "Released", value: "RELEASED" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Completed", value: "COMPLETED" },
];

export const NOTES_BY = [
  {
    label: "Requester",
    value: "R",
  },
  {
    label: "Operator",
    value: "O",
  },
  {
    label: "All",
    value: "B",
  },
];

export const API_PATHS = {
  /* Commonn */
  USERS_PROFILE: "users/profile",
  MY_CUSTOMER_LOCATION_ADDRESS: "my-customer/my-location/address",
  MY_CUSTOMER_ACCESS_GROUP: "customers/my-customer/accessGroup",
  DEFAULT_LOCATION: "defaultLocation",
  /* Orders */
  ORDERS: "orders",
  ITEMS: "items",
  ORDERS_ITEM: "orders/item",
  ORDERS_SEARCH: "orders/search",
  ORDERS_ITEMS_SEARCH: "orders/items/search",
  /* Pathology */
  MY_PATHOLOGY: "mypathology",
  VIEWER_PAYLOAD: "viewer-payload",
  MY_PATHOLOGY_SEARCH: "mypathology/search",
  SLIDE: "slide",
  USERS_FAVORITE: "users/favorite",
  ACCESSION_SCAN_SLIDE: "accession/scan/slide",
  /* Requisitions */
  REQUISITION_MY: "requisition/my",
  REQUISITION_MY_ITEM: "requisition/my/item",
  /* Users */
  USERS: "users",
  ROLES: "roles",
  GROUPS: "groups",
  OKTA_RESET_PASSWORD: "okta-reset-password",
};

export const ERROR_CODES = {
  USER_DELETED: "ODS0230",
  EMAIL_CODE: "ODS0234",
};

export const PAGINATION = {
  pageNum: 1,
  pageSize: 10,
};

export const ROLES_CD = [
  { label: "Order Admin", value: "ORDER_ADMIN" },
  { label: "DPOD Admin", value: "DPOD_ADMIN" },
  { label: "Pathologist", value: "PATHOLOGIST" },
];

export const ENV_CONFIG = "envConfig";

export const MAX_CHARS_STRING = 1200;
export const MAX_CHARS_NOTES = 100;
export const MAX_CHARS_INPUT = 15;
export const MAX_CHARS_INPUT30 = 30;
export const MAX_CHARS_EMAIL_USERID = 74;

export const MAX_INTEGER = 999999999;
export const IDLE_TIMER = 600000;

export const MIN_TIMESTAMP = "00:00:00";
export const MAX_TIMESTAMP = "23:59:59";

export const SUCCESS_ALERT_DURATION = 3000;
